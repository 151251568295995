<template>
  <div>
    <!-- DONT CHANGE insurance cuz of fix in CaseView!!-->
    <!-- added permission here as well, to fix that if the user has only one permission, the view telling above that the user has no permission -->
    <case-view v-if="checkForPermission('advocardPrivate','Mediation')" v-bind:dataTypeName="'advocardPrivateDataType'" v-bind:insurance="'Advocard Private'" v-bind:permission="'Mediation'" v-bind:rebindPermission="'Mediation'" v-bind:dataType="'Mediation'" v-bind:name="'Mediationen'" v-bind:controllerURL="controllerURLAdvocardPrivate" v-bind:allowDataEdit="false"/>
    <case-view v-if="checkForPermission('advocardInternational','Mediation')" v-bind:dataTypeName="'advocardInternationalDataType'" v-bind:insurance="'Advocard International'" v-bind:permission="'Mediation'" v-bind:rebindPermission="'Mediation'" v-bind:dataType="'Mediation'" v-bind:name="'Mediationen'" v-bind:controllerURL="controllerURLAdvocardInternational" v-bind:allowDataEdit="false"/>
  </div>
</template>

<script>

import CaseView from "@/components/Case/CaseView";
import {getApiURL} from "@/configBuilder";
import reactiveStorage from "@/plugins/reactiveStorage";

export default {
  name: "MediationView",
  components: { CaseView },
  data: () => ({
    controllerURLAdvocardPrivate: getApiURL("AdvocardPrivateData"),
    controllerURLAdvocardInternational: getApiURL("AdvocardInternationalData")
  }),
  methods: {
    checkForPermission(insurance, type){
      if (reactiveStorage.user.roles.Admin || reactiveStorage.user.roles.Moderator){
        return true;
      } else {
        return reactiveStorage.user.permissions.insurance[insurance + "DataType"][type];
      }
    }
  },
  computed: {
    reactiveStorage() {
      return reactiveStorage
    }
  },
}
</script>

<style scoped>

</style>
